<template>
  <v-container>
    <v-card color="white">
      <v-card-title style="padding-bottom:0px;">
        <v-icon size="32" color="white" class="iconosVcards"
          >mdi-router-wireless</v-icon
        >
        <v-row>
          <v-col>
            <!-- <div class="font-weight-light" style="margin-left: 3%;padding-bottom:20px">
             <b>Histórico</b>
            </div> -->
          </v-col>
            <v-col class="posicionBotonVolver" align="right">
                <v-btn small @click="volver">Volver</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto pr-6"
        label="Buscar"
        hide-details
        single-line
        style="max-width: 180px"
      />
      <v-divider class="mt-3 ml-6 mr-6" />
      <v-data-table
        class="pl-6 pr-6"
        data-test="vDatatable"
        :headers="headers"
        :items="datosHistorico"
        :search.sync="search"
        multi-sort
      >
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStop }} de {{ items.itemsLength }}
        </template>
        <template v-slot:[`item.fechaFormateada`]="{ item }">
          <span>
            {{
              new Date(item.write_date).toLocaleString("es", {
                year: "numeric",
                month: "2-digit",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            }}
          </span>
        </template>
         <template v-slot:[`item.circuitoftth`]="{ item }">
        <a v-bind:itemEntrada="item" @click="editarRouter(item.ftth_circuito, item.ont_serial)">{{ item.ftth_circuito }}</a>
      </template>
       <template v-slot:[`item.estadoProvisionRouter`]="{ item }">
        <a v-if="item.EstadoProvision==='Provisionado'" v-bind:itemEntrada="item" @click="provisionRouterModal(item.ont_serial)">Provisionado</a>
        <a v-else-if="item.EstadoProvision===''"></a>
        <p v-else v-bind:itemEntrada="item">No provisionado</p>
      </template>

      </v-data-table>
    </v-card>
      <v-dialog
      v-model="dialog_provisionRouter"
      @keydown.esc="dialog_provisionRouter = false"
      persistent
      max-width="350"
    >
      <v-card style="text-align:center;">
        <p style="font-weight: bold; padding-top: 1em;">¿Estás seguro?</p>
        <v-card-text>Desaprovisionará o router...</v-card-text>
        <v-card-actions style="padding-bottom: 1em;">
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="eliminarProvisionRouter()">Confirmar</v-btn>
          <v-btn small color="primary" @click="dialog_provisionRouter = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :right="true"
        :timeout="snack_time"
        :color="snack_color"
    >
        {{ snack_text }}
        <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
    name: 'tablaHistorico',
    components: {},
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            search: '',
            snackbar: false,
            snack_color: '',
            snack_text: '',
            snack_time: -1,
            datosHistorico: [],
            dialog_provisionRouter: false,
            headers: [
                { text: 'Fecha', align: 'left', value: 'fechaFormateada' },
                { text: 'Nº serie router', align: 'left', value: 'ont_serial' },
                { text: 'Circuito', align: 'left', value: 'circuitoftth' },
                { text: 'Estado', align: 'right', value: 'estadoProvisionRouter' },
                { text: 'Id circuito', value: 'ftth_circuito', align: ' d-none' } // necesario para que o buscador funcione correctamente
            ]
        }
    },
    methods: {
        go (msg, color, time) {
            this.snack_time = time
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        volver () {
            this.$router.push('/menu')
        },
        eliminarProvisionRouter () {
            const putEliminarProvisionRouter = async () => {
                const numeroSerie = this.$store.getters.getOntSerial
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.delete(this.url + '/instalador/acs/' + numeroSerie, config)
                } catch (error) {
                    console.log(error)
                }
            }
            const processPutEliminarProvisionRouter = async () => {
                const put_result = await putEliminarProvisionRouter()
                if (put_result) {
                    this.dialog_provisionRouter = false
                    this.listHistorico()
                    this.go('Router desaprovisionado', 'success', 5000)
                } else {
                    this.go('Error al intentar desaprovisionar el router.', 'error', 5000)
                }
            }
            processPutEliminarProvisionRouter()
        },
        provisionRouterModal (serialOnt) {
            this.dialog_provisionRouter = true
            this.$store.dispatch('setOntSerial', serialOnt)
        },
        editarRouter (circuito, serialOnt) {
            this.$store.dispatch('setCircuitoFtth', circuito)
            this.$store.dispatch('setOntSerial', serialOnt)
            this.$router.push('/editarRouter')
        },
        listHistorico () {
            const getHistorico = async () => {
                const config = {
                    headers: {
                        // eslint-disable-next-line
                        accept: "application/json",
                        'Content-Type': 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.get(this.url + '/instalador/router', config)
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$router.push('/').catch((err) => {
                            console.log(err)
                        })
                        localStorage.removeItem('TokenStorage')
                    }
                }
            }
            const processGetHistorico = async () => {
                const get_result = await getHistorico()
                if (get_result) {
                    get_result.data.servicios.forEach((element) => {
                        element.EstadoProvision = ''
                        const getEstadosRouter = async () => {
                            const config = {
                                headers: {
                                    accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    Authorization: this.$store.getters.getToken
                                }
                            }
                            try {
                                return await this.$axios.get(this.url + '/instalador/acs/' + element.ont_serial, config)
                            } catch (error) {
                                if (error.response.status === 401) {
                                    this.$router.push('/').catch((err) => {
                                        console.log(err)
                                    })
                                    localStorage.removeItem('TokenStorage')
                                }
                            }
                        }
                        const processGetEstadosRouter = async () => {
                            const get_result = await getEstadosRouter()
                            if (get_result.data.data) {
                                if (get_result.data.data['Tags.Provisioned']) {
                                    element.EstadoProvision = 'Provisionado'
                                } else {
                                    element.EstadoProvision = 'No provisionado'
                                }
                            } else {
                                element.EstadoProvision = 'No provisionado'
                            }
                        }
                        processGetEstadosRouter()
                    })
                }
                this.$store.dispatch('setHistorico', get_result.data.servicios)
                this.datosHistorico = this.$store.getters.getHistorico
            }
            processGetHistorico()
        }
    },
    beforeMount () {
        this.listHistorico()
    }
}
</script>
